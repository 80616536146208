import { map } from "nanostores"
import { useStore } from "@nanostores/react"
import { modal, ModalFlowProps } from "@/utils"
import { View, Image, Text, Checkbox, Button } from '@/components'
import { onMount, onUpdate } from "@codeleap/common"
import { variantProvider } from "@/app"

import { APIClient } from "@/services"
import { Flag, FlagWithComment, Publication } from "@/types"
import { AppStatus } from "@/redux"

type FlagSelectProps = {
  flags: FlagWithComment[]
  manuscriptId: Publication['id']
}

const selectedFlags = map<{ [x in number]: Partial<FlagWithComment> & {selected: boolean} }>({})

const FlagItem = (flag: Flag) => {
  const store = useStore(selectedFlags)
  
  const onValueChange = (vl: boolean) => {
    selectedFlags.setKey<number>(flag.id, {...(store?.[flag.id] || {}), flag, selected: vl})
  }

  return (
    <View variants={['row', 'alignCenter', 'gap:3']}>
      <Checkbox debugName={'Flag selector'} value={store?.[flag.id]?.selected} onValueChange={(vl) => onValueChange(vl)}/>
      <Image source={flag.icon} style={styles.icon}/>
      <Text text={flag.name} variants={['p1', 'breakWord', 'color:neutral8']}/>
    </View>
)}

export const FlagSelector = modal<FlagSelectProps>({ id: 'flagSelector' }).content((props) => {
  const { nextOrToggle, toggle, manuscriptId, request } = props  as ModalFlowProps<'flag', FlagSelectProps>
  const { data: flags, refetch } = APIClient.Flags.usePublicationFlags(manuscriptId)
  const { data: allFlags } = APIClient.Flags.useFlags()

  const handleSubmit = async () => {
    const selected = Object.values(selectedFlags.value).filter((f) => !!f.selected)
    if (!selected.length && !flags.length) return toggle()

    AppStatus.set('loading')

    try {
      const filteredFlags = selected.map((f) => {
          const exists = flags.find(flag => flag.flag.id === f.flag.id)
          return { flag: f.flag.id, comment: exists ? exists.comment : "" }
        })
      
      await APIClient.Flags.assign({ data: filteredFlags, article: manuscriptId })
      AppStatus.set('done')
      await refetch()
      setTimeout(() => {
        if (filteredFlags.length) {
          /* @ts-ignore*/
          nextOrToggle({ manuscriptId })
        } else {
          request.resolve([])
        }
      }, 250)
    } catch {
      AppStatus.set('idle')
    }

  }

  const handleClose = () => {
    selectedFlags.set({})
    toggle()
  }

  onUpdate(() => {
    const initialFlags = allFlags?.reduce((acc, flag) => {
      acc[flag.id] = {...flag, selected: false}
      return acc;
    }, {})
    const formattedFlags = flags?.reduce((acc, flag) => {
      acc[flag.flag.id] = {...flag, selected: true}
      return acc;
    }, {})
    selectedFlags.set({...initialFlags, ...formattedFlags})
  }, [flags, allFlags])
  
  return (
    <View variants={['column', 'gap:4']} style={styles.wrapper}>
        <View variants={['column', 'gap:2']} style={styles.list}>
          {allFlags?.map(flag => (
            <FlagItem key={flag.id} {...flag as Flag}/>
          ))}
        </View>

      <View style={styles.btnWrapper}>
        <Button 
          debugName="Cancel flag selection"
          text="Cancel" 
          onPress={handleClose}
          variants={['large', 'flat']}
        />
        <Button 
          debugName="Submit flag"
          text="Submit"
          onPress={handleSubmit}
          variants={['large']}
        />
      </View>
    </View>
  )
}).props({
  title: 'Select Flags',
  showClose: false,
  onClose: () => selectedFlags.set({})
})

const LIST_WIDTH = 550
const LIST_HEIGHT = 550

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    maxWidth: LIST_WIDTH,
    width: LIST_WIDTH,
    ...theme.presets.column,

    [theme.media.down('midish')]: {
      width: '100%'
    }
  },
  list: {
    // minHeight: 0,
    // maxHeight: LIST_HEIGHT,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
    overflowY: 'auto',
    overflowX: 'hidden',
    ...theme.spacing.paddingRight(2)
  },
  icon: {
    width: 24,
    height: 24, 
  },
  btnWrapper: {
    ...theme.presets.fullWidth,
    ...theme.presets.row,
    ...theme.presets.center,
    ...theme.spacing.gap(2),
  }
}), true)